import request from '../request'

export function fetchList(params) {
    return request({
        url: '/basic-service/permission/list',
        params
    })
}

export function fetchUserPermissionList(params) {
    return request({
        url: `/permission/byuser?uuid=${params.uuid}`
    })
}
